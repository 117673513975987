<template>
  <div
    class="indicator"
    :id="'cluster:' + cluster"
    :style="
      guildsAffected.length != 0
        ? 'box-shadow: 0px 0px 2px 2px ' +
          extra.color +
          '55, 0px 0px 5px 5px ' +
          extra.color +
          '22'
        : ''
    "
  >
    <p class="tinytitle" :style="{ color: extra.color, marginBottom: '0px' }">
      {{ cluster }} {{ extra.letter }}
    </p>
    <b-popover
      no-fade
      triggers="hover"
      placement="top"
      :target="'cluster:' + cluster"
    >
      <b>Status:</b>
      {{ extra.help }}
      <br />
      <b>Avg latency:</b>
      {{ prettyLatency }}
      <br />
      <div v-for="shard in shardInfo" :key="shard[0]">
        <b>Shard {{ shard[0] }}:</b>
        {{ shard[1] }}
      </div>
      <b>Servers:</b>
      {{ guilds || 0 }}
      <br />
      <b>Uptime:</b>
      {{ timeDelta }}
      <br />
      <b v-if="guildsAffected.length != 0">Servers you know:</b>
      <p v-for="g in guildsAffected" :key="g.id" style="margin-bottom: 0px">
        {{ g.name }}
        <b v-if="g.owner">(you own this server)</b>
      </p>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ClusterIndicator",
  props: [
    "ready",
    "connected",
    "special",
    "cluster",
    "latencies",
    "shard_count",
    "shards_per",
    "guilds",
    "uptime",
    "now",
  ],
  data() {
    return {
      //   ready: this.ready,
      //   connected: this.connected,
      //   special: this.special,
      //   cluster: this.cluster,
      //   latencies: this.latencies
    };
  },
  methods: {
    loggedIn() {
      if (!this.connected) {
        return null;
      }
      let tot = 0;
      for (const sh in this.connected) {
        if (this.connected[sh]) {
          tot++;
        }
      }
      if (tot == this.shards_per) {
        return true;
      } else if (tot == 0) {
        return null;
      }
      return false;
    },
    deconstruct(snowflake) {
      const BINARY = idToBinary(snowflake).padStart(64, "0");
      return parseInt(BINARY.substring(0, 42), 2) + EPOCH;
    },
    idToBinary(num) {
      let bin = "";
      let high = parseInt(num.slice(0, -10), 10) || 0;
      let low = parseInt(num.slice(-10), 10);
      while (low > 0 || high > 0) {
        // tslint:disable-next-line:no-bitwise
        bin = String(low & 1) + bin;
        low = Math.floor(low / 2);
        if (high > 0) {
          low += 5000000000 * (high % 2);
          high = Math.floor(high / 2);
        }
      }
      return bin;
    },
  },
  computed: {
    extra() {
      if (this.ready && this.loggedIn()) {
        return {
          letter: "",
          help: "Ready",
          color: "#70ff69",
        };
      } else if (this.special) {
        return this.special == "LOGGING_IN"
          ? { letter: "L", help: "Logging in", color: "#ffee69" }
          : { letter: "Q", help: "Login queued", color: "#ff8769" };
      } else if (this.cluster == undefined) {
        return { letter: "E", help: "Error", color: "#751700" };
      } else {
        const logged = this.loggedIn();
        switch (logged) {
          case true:
            return { letter: "C", help: "Connected", color: "#d2ff69" };
          case false:
            return {
              letter: "P",
              help: "Partially connected",
              color: "#f8ff69",
            };
          case null:
            return {
              letter: "🔥",
              help: "All shards offline, not queued",
              color: "#ff4d4d",
            };
        }
      }
    },
    prettyLatency() {
      if (!this.latencies) {
        return "N/A";
      }
      let tot = 0;
      for (const l of Object.values(this.latencies)) {
        tot += l;
      }
      return (
        Math.round((tot / Object.keys(this.latencies).length || 1) * 1000) +
        "ms"
      );
    },
    shardInfo() {
      if (!this.connected) {
        return [];
      }
      let shards = [];
      for (
        let i = this.cluster * this.shards_per;
        i < this.cluster * this.shards_per + this.shards_per;
        i++
      ) {
        if (this.connected[i]) {
          shards.push([i, Math.round(this.latencies[i] * 1000) + "ms"]);
        } else {
          shards.push([i, "Unavailable"]);
        }
      }
      return shards;
    },
    guildsAffected() {
      return this.$store.state.discord_guilds.filter((g) => {
        const bin = this.idToBinary(g.id);
        const num = parseInt(bin.substring(0, bin.length - 22), 2);
        return (
          Math.floor((num % this.shard_count) / this.shards_per) ==
            this.cluster && !g.link
        );
      });
    },
    timeDelta() {
      if (!this.uptime) {
        return "N/A";
      }
      let seconds = Math.floor(Math.abs(this.uptime - this.now / 1000));
      let hours = Math.floor(seconds / 3600);
      let remainder = seconds % 3600;
      const minutes = Math.floor(remainder / 60);
      seconds = remainder % 60;
      let days = Math.floor(hours / 24);
      hours = hours % 24;
      const weeks = Math.floor(days / 7);
      days = days % 7;
      if (weeks) {
        return `${weeks}w ${days}d ${hours}h`;
      }
      if (days) {
        return `${days}d ${hours}h ${minutes}m`;
      }
      if (hours) {
        return `${hours}h ${minutes}m`;
      }
      if (minutes) {
        return `${minutes}m ${seconds}s`;
      }
      return `${seconds}s`;
    },
  },
};
</script>

<style scoped>
.indicator {
  width: 55px;
  height: 55px;
  background-color: #223;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>