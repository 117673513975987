<template>
  <div class="animated fadeIn col-sm-10 offset-sm-1">
    <b-row>
      <b-col sm="12">
        <b-card header-tag="header">
          <h2 class="subtitle">Bot status</h2>

          <p>This page automatically refreshes every 30 seconds. Next update in: {{countDown}}</p>
          <p style="cursor:pointer" v-b-toggle.collapse-1>What do the letters mean? (click me)</p>
          <b-collapse id="collapse-1">
            <b class="tinytitle" style="color:#70ff69">No letter</b> - Working as intentional!
            <br />
            <b class="tinytitle" style="color:#d2ff69">C</b> - Connected. The bot has connected to discord, but it might not respond to all commands.
            <br />
            <b class="tinytitle" style="color:#f8ff69">P</b> - Partially connected. Like connected, except some servers won't have any commands work.
            <br />
            <b class="tinytitle" style="color:#ffee69">L</b> - Currently in the process of logging in.
            <br />
            <b class="tinytitle" style="color:#ff8769">Q</b> - Offline and waiting for its turn to log in.
            <br />
            <b class="tinytitle">🔥</b> - Not only is the cluster offline, it hasn't shown any signs it's trying to reconnect.
            <hr />
          </b-collapse>
          <b-alert
            v-if="unhealthyShards === 0"
            show
            variant="success"
            class="col-sm-12 col-md-8 col-lg-6 col-xl-4"
          >All systems operational</b-alert>
          <b-alert
            v-else
            show
            variant="danger"
            class="col-sm-12 col-md-8 col-lg-6 col-xl-4"
          >{{unhealthyShards + (unhealthyShards !== 1 ? ' shards are ' : 'shard is ')}}having issues</b-alert>
          <div v-if="statuses.length != 0" class="cluster__container">
            <cluster-indicator
              v-bind="combine(k, s)"
              v-for="(k, s) in filteredClusters"
              :key="s.cluster"
            ></cluster-indicator>
          </div>

          <div class="sk-circle-fade" v-else>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueNotifications from "vue-notifications";
import ClusterIndicator from "./ClusterIndicator";

export default {
  name: "Status",
  components: { ClusterIndicator },
  data: function () {
    return {
      statuses: [],
      guildId: null,
      counter: 0,
      now: new Date().getTime(),
      task: null,
      ticker: null,
      shard_count: 0,
    };
  },
  methods: {
    combine(k, s) {
      k.cluster = s;
      k.shards_per = this.shards_per;
      k.shard_count = this.shard_count;
      k.now = this.now;
      return k;
    },
    fetchData() {
      if (!window.location.href.endsWith("/status")) {
        return;
      }
      this.axios
        .get("/api/v1/status")
        .then((r) => {
          this.statuses = r.data.statuses;
          this.shard_count = r.data.shard_count;
          this.shards_per = r.data.shards_per_cluster;
          this.counter = 0;
        })
        .catch((e) => {});
    },
    deconstruct(snowflake) {
      const BINARY = idToBinary(snowflake).padStart(64, "0");
      return parseInt(BINARY.substring(0, 42), 2) + EPOCH;
    },
    idToBinary(num) {
      let bin = "";
      let high = parseInt(num.slice(0, -10), 10) || 0;
      let low = parseInt(num.slice(-10), 10);
      while (low > 0 || high > 0) {
        // tslint:disable-next-line:no-bitwise
        bin = String(low & 1) + bin;
        low = Math.floor(low / 2);
        if (high > 0) {
          low += 5000000000 * (high % 2);
          high = Math.floor(high / 2);
        }
      }
      return bin;
    },
  },
  created: function () {
    this.fetchData();
    this.task = setInterval(this.fetchData, 30000);
    this.ticker = setInterval(() => {
      this.counter++;
      this.now += 1000;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.task);
    clearInterval(this.ticker);
  },
  computed: {
    filteredClusters() {
      if (!this.guildId || !this.statuses) {
        return this.statuses;
      }
      let res = {};
      const bin = this.idToBinary(this.guildId);
      const num = parseInt(bin.substring(0, bin.length - 22), 2);
      const cluster = Math.floor((num % this.shard_count) / 8);
      for (const key in this.statuses) {
        if (key == cluster) {
          res[key] = this.statuses[key];
        }
      }
      return res;
    },
    countDown() {
      return 30 - (this.counter % 30);
    },
    unhealthyShards() {
      let tot = 0;
      for (let cluster of Object.values(this.statuses)) {
        for (const sh in cluster.connected) {
          if (cluster.connected[sh]) {
            tot++;
          }
        }
      }
      return this.shard_count - tot;
    },
  },
};
</script>

<style scoped>
.cluster__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
</style>

<style src="spinkit/spinkit.min.css"></style>